import React from "react"
import FotoKontigo from "@components/pageCasosdeExito/image/imgPhotoEmpresa/proempresa/img1.png"
import FotoKontigo2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/proempresa/img2.png"

//logo de empresas clientes
import Proempresa from "@components/pageCasosdeExito/image/logoscustomer/Proempresa.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import gestion from "@components/pageCasosdeExito/image/iconcards/gestion.svg"
import campañas from "@components/pageCasosdeExito/image/iconcards/campañas.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/proempresa/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/proempresa/img2.svg"

const text1 = (
  <span className="">
    Financiera ProEmpresa, con más de 26 años en el rubro financiero, es una entidad especializada en Microfinanzas, dedicada a la intermediación financiera mediante el otorgamiento de créditos, captación de ahorros y servicios.
    <br /> <br />
    Con Beex, Financiera ProEmpresa ha logrado aumentar las ventas de sus productos de créditos por canal digital en +30%, aprovechando el potencial de la comunicación por la API oficial de WhatsApp Business y sus campañas masivas de marketing.
  </span>
)

const text2 = (
  <span>
    Financiera ProEmpresa, fundada en el año 1997, es una de las principales financieras peruanas que cuenta con un amplio portafolio, como productos de créditos, ahorros y seguros.
    <br /> <br />
    Desde la implementación de Beex, el equipo de marketing de ProEmpresa ha logrado un alta exitosa de la herramienta y sus diferentes funcionalidades, como la gestión omnicanal y campañas masivas. De hecho, esto último es uno de los puntos más fuertes en el cuál ProEmpresa se apoya para mejorar sus resultados, logrando aumentar en +30% sus ventas digitales.
    <br /> <br />
    Como nos cuenta su equipo de marketing, a través de Nancy Rodriguez, Marketing Leader en la organización, Financiera Proempresa ha sacado provecho de los envíos masivos de WhatsApp al tener un alto índice de conversión, comparado con otros canales digitales.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Usamos diariamente las campañas masivas por WhatsApp. Esto, nos permite promocionar nuestros créditos y se han obtenido muy buenos resultados, ya que ha reforzado la cercanía y la fidelización para atender las necesidades de financiamiento de nuestros clientes.”
  </p>
)

const text5 = (
  <span>
    Como sabemos, la versión API de WhatsApp Business goza de una gran versatilidad para todos los sectores empresariales, e indiscutiblemente también para el sector financiero, donde bancos, seguros, financieras, cajas y muchos más lo usan para mejorar sus procesos.
    <br /> <br />
    Para este sector en específico, para el financiero, WhatsApp Business API es una herramienta ideal, ya que brinda todas las características de escalabilidad y seguridad que esta industria necesita.
    <br /> <br />
    Para el caso de financiera ProEmpresa, como nos cuenta el equipo de marketing, han logrado mejorar sus métricas principales de conversión y satisfacción, ya que, adicional de mejorar sus ventas, han logrado mejorar su índice de satisfacción de clientes en +20%.
  </span>
)

const text6 = (
  <span>
    “El enviar campañas publicitarias de manera masiva es un punto importante, pero no solo lo es para vender, sino que logramos brindar información relevante a nuestros clientes, siendo capaces de derivarlos a las agencias adecuadas para que puedan realizar sus desembolsos.”
  </span>
)
const text7 = (
  <span>
    Como lo venimos contando, WhatsApp API para empresas no se limita a ciertas características, por el contrario, cuenta con infinidad de casos de uso en los cuales todas las industrias pueden aprovechar.
    <br /> <br />
    Para este caso, con financiera ProEmpresa, la organización ha sido capaz de aprovechar el potencial del canal y sus funcionalidades extras que brinda gracias a su integración con Beex, plataforma omnicanal de Beex.
    <br /> <br />
    Tal como lo detalla el equipo de marketing de ProEmpresa, el hacer uso de un software omnicanal que brinde estas características, además de una experiencia de usuario agradable, hace que el uso diario de nuestra solución logre verdaderamente resultados importantes, no solo de cara al cliente, sino que con los ejecutivos de cuenta que usan la plataforma.
    <br /> <br />
    Algunas de estas son las respuestas rápidas de WhatsApp, supervisión de agentes en tiempo real y la gestión multiagente. Esto último ha hecho que el enrutamiento de conversaciones sea más dinámico y que la entidad tenga una visión real de su operación digital.
  </span>
)

const text8 = (
  <span>
    “Con Beex, somos capaces de monitorear y realizar un mejor control de nuestros ejecutivos de Negocios. Ahora, de haber alguno desconectado o que no realice un proceso adecuado, somos capaces de notificarlo de inmediato y que esto no se conviertas en una mala experiencia para nuestros clientes.”
  </span>
)
const text9 = (
  <span>
    Como es costumbre, en Beex estamos orgullosos de la calidad de soporte post venta que brindamos a nuestros clientes, y esto, ha sido algo que financiera ProEmpresa ha experimentado desde el día uno con nuestras soluciones.
    <br /> <br />
    Sabemos que el adoptar un canal nuevo para una operación que involucra dinero es súper importante para cualquier entidad, ello lleva a que el compromiso de Beex Service Desk con sus clientes de este tipo de industria sea total a la hora de acompañarlos.
    <br /> <br />
    Es así que, tal como nos cuenta el equipo de marketing de ProEmpresa, los tiempos de respuesta y resoluciones del equipo han sido ideales para su experiencia con nuestras soluciones.
  </span>
)

const text10 = (
  <span>
    “El acompañamiento de Beex es bastante bueno. Usualmente, responden de inmediato y dan solución a nuestros casos el mismo día o máximo al día siguiente. En general, los recomiendo porque esta experiencia nos ha servido para recuperar clientes con los que no teníamos contacto y hemos podido fidelizarlos nuevamente.”
  </span>
)
const text11 = (
  <span>
    Estos son algunos procesos con los que Beex, a través de Beex, ayuda a financiera ProEmpresa en su operación de atención al cliente, marketing y ventas.
  </span>
)

const data = {
  intro: {
    image: Proempresa,
    link: "#",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoKontigo,
    text4: "",
    title5: "WhatsApp para el sector financiero",
    text5: text5,
    text6: text6,
    title7: "Funcionalidades Enterprise para WhatsApp",
    text7: text7,
    text8: text8,
    fototwo: FotoKontigo2,
    title9: "Calidad de soporte post venta",
    text9: text9,
    title10: "Acompañamiento y respaldo que se siente",
    text10: text10,
    title11: "Monitoreo de calidad con el API de WhatsApp Business",
    text11: text11,

    metric1: metrica1,
    metric2: metrica2,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para su operación de atención al cliente, marketing y ventas. Beex ha logrado mejorar el contacto y comunicación son sus prospectos y clientes, mejorando sus índices de satisfacción y conversión.",
    },
    {
      title: "Campañas masivas",
      img: campañas,
      info:
        "Envío de campañas masivas de WhatsApp con fines publicitarios para su operación de marketing y ventas. Proceso simple y rápido con el uso de plantillas pre-aprobadas de WhatsApp, lo que les ha permitido mejorar su tasa de conversión.",
    },
    {
      title: "Gestión multiagente",
      img: gestion,
      info:
        "Creación y administración de múltiples usuarios de diferentes perfiles para gestionar bajo una sola cuenta de WhatsApp. Gestión de colas de atención asignadas a chatbots con enrutamiento inteligente de casos.",
    },
  ],
}

export default data
